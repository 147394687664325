import * as React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  useRefresh,
} from "react-admin";
import { MemedRegister } from "../../components/atoms/MemedRegister";

export const PrescriberFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Busca por nome"
      style={{ minWidth: 300 }}
      source="search"
      alwaysOn
    />
  </Filter>
);

export const PrescriberList = (props) => {
  const refresh = useRefresh();

  return (
    <List
      filters={<PrescriberFilter />}
      title="Lista de Prescritores"
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ReferenceField label="Usuário" source="user" reference="user">
          <TextField source="full_name" label="Usuario" />
        </ReferenceField>
        <TextField source="board_code" label="Conselho" />
        <TextField source="board_number" label="Número do conselho" />
        <TextField source="board_state" label="Estado" />
        <MemedRegister onComplete={() => refresh()} />
        <DateField showTime={true} source="created_at" label="Criado em" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
