import { Link, Button, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import { axiosInstance } from "../../axios";

export function MemedRegister(props) {
  const record = useRecordContext(props);
  const id = record.id;
  const [isLoading, setIsLoading] = useState(false);

  const Toast = useToast();

  async function register() {
    try {
      setIsLoading(true);
      let { data, status } = await axiosInstance.post(
        `/api/v1/telemedicine/memed/create-subscriber`,
        {
          prescriber_id: id,
        }
      );
      setIsLoading(false);
      Toast({
        title: "Sucesso",
        description: "Cadastro realizado com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
      props.onComplete();
    } catch (error) {
      console.log(error.response.data);
      setIsLoading(false);
      Toast({
        title: "Erro",
        description: error.response.data.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (record?.memed_id) {
    return (
      <Link href={`http://telemedicina.partiu.com.br.s3-website-sa-east-1.amazonaws.com/login/${record.token}`} isExternal>
        Acessar Memed
      </Link>
    );
  }

  return (
    <Button
      variant={"ghost"}
      fontSize="10pt"
      size="sm"
      isLoading={isLoading}
      onClick={register}
      colorScheme="green"
    >
      Cadastrar na Memed
    </Button>
  );
}
