import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const PrescriberForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput required={true} label="Usuário" source="user" reference="user">
      <AutocompleteInput optionText="full_name" />
    </ReferenceInput>
    <SelectInput
      label="Conselho"
      required={true}
      source="board_code"
      choices={[
        { id: "CRM", name: "CRM" },
        { id: "CRO", name: "CRO" },
        { id: "COREN", name: "COREN" },
      ]}
    />
    <TextInput
      required={true}
      source="board_number"
      label="Número do Conselho"
    />
    <SelectInput
      required={true}
      label="Estado"
      source="board_state"
      choices={[
        { id: "AC", name: "Acre" },
        { id: "AL", name: "Alagoas" },
        { id: "AM", name: "Amazonas" },
        { id: "AP", name: "Amapá" },
        { id: "BA", name: "Bahia" },
        { id: "CE", name: "Ceará" },
        { id: "DF", name: "Distrito Federal" },
        { id: "ES", name: "Espírito Santo" },
        { id: "GO", name: "Goiás" },
        { id: "MA", name: "Maranhão" },
        { id: "MG", name: "Minas Gerais" },
        { id: "MS", name: "Mato Grosso do Sul" },
        { id: "MT", name: "Mato Grosso" },
        { id: "PA", name: "Pará" },
        { id: "PB", name: "Paraíba" },
        { id: "PE", name: "Pernambuco" },
        { id: "PI", name: "Piauí" },
        { id: "PR", name: "Paraná" },
        { id: "RJ", name: "Rio de Janeiro" },
        { id: "RN", name: "Rio Grande do Norte" },
        { id: "RO", name: "Rondônia" },
        { id: "RR", name: "Roraima" },
        { id: "RS", name: "Rio Grande do Sul" },
        { id: "SC", name: "Santa Catarina" },
        { id: "SE", name: "Sergipe" },
        { id: "SP", name: "São Paulo" },
        { id: "TO", name: "Tocantins" },
      ]}
    />
  </SimpleForm>
);
