import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";


import { useFormState } from "react-final-form";

const DiscountModeField = ({ children, mode, ...props }) => {
  const { values } = useFormState();

  if (values.mode !== mode) return <></>;

  return React.cloneElement(children, props);
};

const DiscountTypeField = ({ children, ...props }) => {
  const { values } = useFormState();

  if (values.type !== "discount") return <></>;

  return React.cloneElement(children, props);
};

const CompanyStoreReferenceField = ({ children, ...props }) => {
  const { values } = useFormState();

  return (
    <ReferenceArrayInput
      {...props}
      reference="store"
      source="stores"
      label="Lojas"
      perPage={99999}
      filter={{ company: values.company }}
      allowEmpty={false}
      isRequired
    >
      <SelectArrayInput optionText="street" />
    </ReferenceArrayInput>
  );
};

export const DiscountTabbedForm = (props) => {
  const [mode, setMode] = useState("local");

  useEffect(() => {
    setMode(props.record.mode);
  }, [props.record.mode]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Informações Básicas">
        <ReferenceInput
          isRequired
          source="company"
          reference="company"
          label="Empresa"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="voucher_batch"
          reference="voucher_batch"
          label="Vouchers"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput isRequired source="name" label="Nome" />
        <NumberInput source="vindi_product_id" label="Código de Produto da Vindi" />
        <TextInput
          isRequired
          multiline
          fullWidth
          source="description"
          label="Descrição"
        />
        <SelectInput
          source="mode"
          label="Modo"
          choices={[
            { id: "online", name: "E-Commerce" },
            { id: "local", name: "Loja Física" },
          ]}
        />
        <DiscountModeField mode="local">
          <CompanyStoreReferenceField  />
        </DiscountModeField>
        <DiscountModeField mode="online">
          <TextInput isRequired source="link" label="Link" />
        </DiscountModeField>
        <DiscountModeField mode="online">
          <TextInput isRequired source="coupon" label="Cupom" />
        </DiscountModeField>
        <SelectInput
          source="type"
          label="Tipo"
          choices={[
            { id: "discount", name: "Desconto" },
            { id: "treat", name: "Mimo" },
            { id: "buy_and_gain", name: "Compre e Ganhe" },
          ]}
        />
        <DiscountTypeField>
          <NumberInput
            isRequired
            source="discount"
            label="Porcentagem de Desconto"
          />
        </DiscountTypeField>
        <DiscountModeField mode="local">
          <BooleanInput
            defaultValue={false}
            source="limited_codes"
            label="Códigos Limitados"
          />
        </DiscountModeField>
        <BooleanInput defaultValue={true} source="active" label="Ativo" />
        <BooleanInput
          source="in_all_stores"
          label="Disponível em todas as lojas"
        />
      </FormTab>
      <FormTab label="Detalhes">
        <NumberInput source="original_price" label="Ticket Médio" />
        <NumberInput source="buy_price" label="Preço de Compra" />
        <NumberInput source="sale_price" label="Preço de Venda" />
        <BooleanInput source="pop_up" label="Aparecer como Pop-up" />
        <BooleanInput source="featured" label="Destaque" />
        <TextInput multiline source="rules" label="Regulamento" fullWidth />
        <TextInput multiline source="how_to_use" label="Como Usar" fullWidth />
        {props.record.image ? (
          <ImageField source="image" label="Imagem Atual" />
        ) : (
          <></>
        )}
        <ImageInput source="image" label="Imagem Principal (1366x382)">
          <ImageField source="image" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  );
};
