import React from "react";
import {
  Admin,
  Resource,
  TextField,
  AutocompleteInput,
  RouteWithoutLayout,
} from "react-admin";
import authProvider from "./providers/auth";
import dataProvider from "./providers/data";
import WorkIcon from "@material-ui/icons/Work";
import WebIcon from "@material-ui/icons/Web";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PeopleIcon from "@material-ui/icons/People";
import PlusOne from "@material-ui/icons/PlusOne";
import NotificationsIcon from "@material-ui/icons/Notifications";

import Filter from "@material-ui/icons/Filter";

import ptBrMessages from "ra-language-pt-br";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Dashboard } from "./screens/Dashboard";
import { AgreementList } from "./resources/agreement/AgreementList";
import { UsageList } from "./resources/usage/UsageList";
import { UserList } from "./resources/user/UserList";
import { AgreementCreate } from "./resources/agreement/AgreementCreate";
import { AgreementEdit } from "./resources/agreement/AgreementEdit";

import "./global.css";
import { myTheme } from "./layout/theme";
import { LandingList } from "./resources/landing/LandingList";
import { LandingCreate } from "./resources/landing/LandingCreate";
import { LandingEdit } from "./resources/landing/LandingEdit";
import { UserCreate } from "./resources/user/UserCreate";
import { UserEdit } from "./resources/user/UserEdit";
import { CreditCard } from "@material-ui/icons";
import { fromFields } from "./components/core/fromFields";
import { MagazineList } from "./resources/magazine/MagazineList";
import { MagazineCreate } from "./resources/magazine/MagazineCreate";
import { AgreementPlanForm } from "./resources/agreement_plan/AgreementPlanForm";
import { discountApp } from "./resources/discounts_app";
import { booksApp } from "./resources/books_app";
import { promocodeApp } from "./resources/promocode_app";
import { communicationApp } from "./resources/communication_app";
import MyLayout from "./layout/Layout";
import { integrationsApp } from "./resources/integration_app";
import { Flux } from "./resources/flux/Flux";
import { subscriptionApp } from "./resources/subscription_app";
import { NotificationList } from "./resources/notification_center/NotificationList";
import { NotificationCreate } from "./resources/notification_center/NotificationCreate";
import BooksDashboard from "./custom-pages/booksDashboard";
import { FluxList } from "./resources/flux_v2/FluxList";
import { FluxCreate } from "./resources/flux_v2/FluxCreate";
import { MagazineTemplateList } from "./resources/magazine_template/MagazineTemplateList";
import { MagazineTemplateCreate } from "./resources/magazine_template/MagazineTemplateCreate";
import { MagazineTemplateEdit } from "./resources/magazine_template/MagazineTemplateEdit";
import AgreementDefaultPlan from "./custom-pages/agreementDefaultPlan";
import { PrescriberList } from "./resources/prescriber/PrescriberList";
import { PrescriberEdit } from "./resources/prescriber/PrescriberEdit";
import { PrescriberCreate } from "./resources/prescriber/PrescriberCreate";

const messages = {
  "pt-br": ptBrMessages,
};

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  "pt-br"
);

export function App() {
  return (
    <Admin
      theme={myTheme}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      layout={MyLayout}
      customRoutes={[
        // <RouteWithoutLayout
        //     exact
        //     path="/books-dashboard"
        //     component={Flux}
        // />
        <RouteWithoutLayout
          exact
          path="/books-dashboard"
          component={BooksDashboard}
          noLayout={false}
        />,
        <RouteWithoutLayout
          exact
          path="/agreement-default-plans"
          component={AgreementDefaultPlan}
          noLayout={false}
        />,
      ]}
    >
      {discountApp}
      {booksApp}
      {promocodeApp}
      {communicationApp}
      {integrationsApp}
      {subscriptionApp}
      <Resource
        id="agreement"
        options={{ label: "Convênios" }}
        icon={WorkIcon}
        name="agreement"
        list={AgreementList}
        create={AgreementCreate}
        edit={AgreementEdit}
      />
      <Resource
        id="agreement_plans"
        options={{ label: "Planos" }}
        icon={CreditCard}
        name="agreement_plans"
        {...fromFields(AgreementPlanForm, [
          { source: "name", field: TextField, label: "Nome" },
          { source: "type", field: TextField, label: "Tipo" },
          {
            source: "for_agreement",
            reference: "agreement",
            input: <AutocompleteInput source="name" optionText="name" />,
            field: <TextField source="name" />,
            filter: true,
            alwaysOn: true,
            label: "Convênio",
          },
          { source: "value", field: TextField, label: "Valor" },
        ])}
      />
      <Resource
        id="notification-center"
        options={{ label: "Central de Notificações" }}
        name="notifications_emails"
        icon={NotificationsIcon}
        create={NotificationCreate}
        list={NotificationList}
      />
      <Resource
        id="landing"
        options={{ label: "Landing" }}
        icon={WebIcon}
        name="landing"
        list={LandingList}
        create={LandingCreate}
        edit={LandingEdit}
      />
      <Resource
        id="flux"
        options={{ label: "Fluxo" }}
        name="flux"
        icon={PlusOne}
        list={Flux}
      />
      <Resource
        id="flux_v2"
        options={{ label: "Gerenciador de Apps" }}
        name="agreement_app"
        icon={PlusOne}
        edit={FluxCreate}
        create={FluxCreate}
        list={FluxList}
      />
      <Resource
        id="usage"
        options={{ label: "Utilizações" }}
        icon={ReceiptIcon}
        name="usage"
        list={UsageList}
      />
      <Resource
        id="user"
        options={{ label: "Usuários" }}
        icon={PeopleIcon}
        name="user"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />

      <Resource
        id="magazine"
        options={{ label: "Revistas" }}
        icon={Filter}
        name="magazine"
        list={MagazineList}
        create={MagazineCreate}
      />
      <Resource
        id="magazine_template"
        options={{ label: "Revistas Template" }}
        icon={Filter}
        name="magazine_template"
        list={MagazineTemplateList}
        create={MagazineTemplateCreate}
        edit={MagazineTemplateEdit}
      />
      <Resource
        id="prescriber"
        options={{ label: "Telemedicina - Médicos" }}
        icon={PeopleIcon}
        name="prescriber"
        list={PrescriberList}
        create={PrescriberCreate}
        edit={PrescriberEdit}
      />
    </Admin>
  );
}
