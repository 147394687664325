import { useEffect, useState, useRef } from "react";

export function InputImage({ valueImage, value = null, onUpload, imageName }) {
  const [image, setImage] = useState(value);
  const origin = window.location.origin;

  const ref = useRef();

  const handleUpload = (e) => {
    onUpload(e);
    setImage(URL.createObjectURL(e));
  };

  const urlToFile = async (url, filename) => {
    const response = await fetch(`${origin}${url}`);
    const blob = await response.blob();

    return new File([blob], `${filename}.${blob.type.split('/')[1]}`, { type: blob.type });
  };

  useEffect(() => {
    if (valueImage) {
      urlToFile(valueImage, imageName).then((file) => {
        handleUpload(file);
      });
    }
  }, [imageName, valueImage]);

  useEffect(() => {
    if (value && typeof value === "string") {
      setImage(value);
    }
  }, [value]);

  return (
    <>
      <input
        ref={ref}
        accept="image/*"
        type="file"
        onChange={(e) => handleUpload(e.target.files[0])}
      />
      <img width={150} height={150} src={image} />
    </>
  );
}
