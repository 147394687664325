import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";

export const Step2 = ({ isMobile, state, setState }) => {
  console.log(state);
  return (
    <Grid container>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "40px",
          color: "#333333",
          padding: "8px 16px",
        }}
      >
        Módulos de Vinculação
      </Typography>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.promocode_module}
              onChange={(e) => {
                setState({ ...state, promocode_module: e.target.checked });
              }}
            />
          }
          label="Módulo Vinculação por Promocode"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.cpf_module}
              onChange={(e) => {
                setState({ ...state, cpf_module: e.target.checked });
              }}
            />
          }
          label="Módulo Vinculação de CPF"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.physical_card_module}
              onChange={(e) => {
                setState({ ...state, physical_card_module: e.target.checked });
              }}
            />
          }
          label="Módulo Vinculação por Cartão Físico"
        />
      </Grid>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "40px",
          color: "#333333",
          padding: "8px 16px",
        }}
      >
        Módulos de Vantagens
      </Typography>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.local_module}
              onChange={(e) => {
                setState({ ...state, local_module: e.target.checked });
              }}
            />
          }
          label="Módulo Vantagens Locais"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.ecommerce_module}
              onChange={(e) => {
                setState({ ...state, ecommerce_module: e.target.checked });
              }}
            />
          }
          label="Módulo Vantagens Ecommerce"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.bookstore_module}
              onChange={(e) => {
                setState({ ...state, bookstore_module: e.target.checked });
              }}
            />
          }
          label="Módulo Biblioteca"
        />
      </Grid>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "40px",
          color: "#333333",
          padding: "8px 16px",
        }}
      >
        Módulos de Funcionalidades
      </Typography>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.referral_module}
              onChange={(e) => {
                setState({ ...state, referral_module: e.target.checked });
              }}
            />
          }
          label="Módulo Indicações"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.econometer_module}
              onChange={(e) => {
                setState({ ...state, econometer_module: e.target.checked });
              }}
            />
          }
          label="Módulo Econômetro"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.be_partner_module}
              onChange={(e) => {
                setState({ ...state, be_partner_module: e.target.checked });
              }}
            />
          }
          label="Módulo Seja Parceiro"
        />
      </Grid>

      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.plan_sale_module}
              onChange={(e) => {
                setState({ ...state, plan_sale_module: e.target.checked });
              }}
            />
          }
          label="Módulo Venda de Planos"
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel
          required
          control={
            <Switch
              checked={state.push_notifications_module}
              onChange={(e) => {
                setState({
                  ...state,
                  push_notifications_module: e.target.checked,
                });
              }}
            />
          }
          label="Módulo Push Notifications"
        />
      </Grid>
    </Grid>
  );
};
