import React from "react";
import {
  AutocompleteInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";


export const LandingForm = (props) => {

  const transform = data => ({
    ...data,
    email: data.email ? data.email : "Teste"
});
  return (
    <SimpleForm {...props} transform={transform} sanitizeEmptyValues={false} >
      <ReferenceInput reference="agreement" source="agreement" label="Convênio">
        <AutocompleteInput optionName="name" />
      </ReferenceInput>
      <TextInput source="email" label="Email" />
      <SelectInput
        label="Tipo"
        source="type"
        choices={[
          { id: "club", name: "Clube de Vantagens" },
          { id: "bookstore", name: "Biblioteca" },
        ]}
      />
      <TextInput
        required={true}
        source="slug"
        fullWidth
        label="Nome da Página (tudo em minúsculo)"
      />
      <ImageField source="phone_image" label="Logo Atual" />
      <ImageInput source="phone_image" label="Logo">
        <ImageField source="phone_image" />
      </ImageInput>
      <TextInput
        multiline
        fullWidth
        source="welcome_text"
        label="Texto da Home"
      />
      <ColorInput source="welcome_text_color" label="Cor do Texto da Home" />
      <TextInput
        source="metadata.buttonRegisterName"
        fullWidth
        defaultValue={"Registro"}
        label="Mude o texto do botão de cadastro"
      />
      <BooleanInput
        label="Habilitar botão de Assinatura"
        defaultValue={true}
        source="metadata.subscription"
      />
      <BooleanInput
        label="Habilitar editar perfil"
        defaultValue={true}
        source="metadata.profileEdit"
      />
      <TextInput
        source="metadata.buttonCpfName"
        fullWidth
        defaultValue={"CPF/CNPJ Vinculado"}
        label="Mude o texto do botão de 'CPF/CNPJ Vinculado'"
      />
      <TextInput
        source="metadata.nomeLanding"
        fullWidth
        label="Mude o nome da Landing"
      />
    </SimpleForm>
  );
};
